import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';
import type { MRT_TableOptions } from 'material-react-table';

interface TableProps<TData extends Record<string, unknown>> {
  tableOptions: MRT_TableOptions<TData>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Table<TData extends Record<string, any> = Record<string, any>>({ tableOptions }: TableProps<TData>) {
  const table = useDvtTable(tableOptions);

  return <MaterialReactTable table={table} />;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDvtTable<TData extends Record<string, any> = Record<string, any>>(
  tableOptions: MRT_TableOptions<TData>
) {
  return useMaterialReactTable<TData>({
    enableGlobalFilterModes: true,
    enablePagination: true,
    localization: MRT_Localization_NL,
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        backgroundColor: 'background.paper',
        borderRadius: 2,
        overflow: 'hidden',
      },
      variant: 'outlined',
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    positionGlobalFilter: 'left',
    positionToolbarAlertBanner: 'bottom',

    ...tableOptions,
  });
}
