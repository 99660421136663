import { Delete } from '@mui/icons-material';
import { Autocomplete, Button, IconButton, TextField, Typography } from '@mui/material';
import {
  type MRT_ColumnDef,
  type MRT_RowVirtualizer,
  type MRT_TableOptions,
  MaterialReactTable,
} from 'material-react-table';
import { useMemo, useRef, useState } from 'react';
import type {
  ModelsAssignableServiceRole,
  ModelsOrganizationResponse,
  ModelsServiceRoleAssignmentToUserForServiceRole,
  ModelsServiceRoleChild,
  ModelsUserResponseV2,
} from '#edsn/api/idm-bff';
import { useDvtTable } from '../../table/Table';
import { organizationTypeToText } from '../../utils/organizationType';
import { ExternalOrganizationAutocomplete } from '../organization/external-organization-autocomplete/ExternalOrganizationAutocomplete';
import { OrganizationTooltip } from '../organization/organization-tooltip/OrganizationTooltip';
import { isOrganizationPartOfOrganizations } from './organization-part-of-organization';

interface ServiceRoleUserTableProps {
  serviceRoleName: string;
  organizations: ModelsOrganizationResponse[];
  users: ModelsUserResponseV2[];
  editableServiceRoles: ModelsAssignableServiceRole[];
  value: ModelsServiceRoleAssignmentToUserForServiceRole[];
  tableOptions?: Omit<MRT_TableOptions<RowData>, 'columns' | 'data'>;
  onChange: (data: ModelsServiceRoleAssignmentToUserForServiceRole[]) => void;
}

type RowData = ModelsServiceRoleAssignmentToUserForServiceRole & {
  children: RowData[];
  isChild: boolean;
};

function mapAssignableServiceRoleChildToRowData(child: ModelsServiceRoleChild, userId: string): RowData {
  return {
    children: child.children.map(child => ({
      children: [],
      isChild: true,
      organizationId: child.organizationId,
      userId,
    })),
    isChild: true,
    organizationId: child.organizationId,
    userId,
  };
}

export function ServiceRoleUserTable({
  tableOptions = {},
  organizations,
  editableServiceRoles,
  value,
  onChange,
  users,
  serviceRoleName,
}: ServiceRoleUserTableProps) {
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);

  const editableRows = useMemo((): RowData[] => {
    return editableServiceRoles
      .filter(role => role.serviceRoleName === serviceRoleName)
      .flatMap(role =>
        users
          .filter(user => isOrganizationPartOfOrganizations(user.organizations, role.organizationId))
          .map(user => ({
            children: role.children.map(child => mapAssignableServiceRoleChildToRowData(child, user.id)),
            isChild: false,
            organizationId: role.organizationId,
            userId: user.id,
          }))
      );
  }, [serviceRoleName, users]);

  const assignedRows = useMemo((): RowData[] => {
    return value.map(role => ({
      children: role.children.map(child => mapAssignableServiceRoleChildToRowData(child, role.userId)),
      isChild: false,
      organizationId: role.organizationId,
      userId: role.userId,
    }));
  }, [value, editableRows]);

  const notAssignedRows = useMemo(
    (): RowData[] =>
      editableRows.filter(role => !assignedRows.some(assignedRole => isRoleAssignmentEqual(assignedRole, role))),
    [value, editableRows]
  );

  const assignableOrganizations = useMemo(
    () => organizations.filter(org => notAssignedRows.some(role => role.organizationId === org.externalId)),
    [notAssignedRows]
  );

  const [selectedOrganization, setSelectedOrganization] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

  const columns = useMemo<MRT_ColumnDef<RowData>[]>(
    () => [
      {
        Cell: ({ row }) => {
          const name: string = row.getValue('organizationId') ?? 'Onbekende organisatie';
          const organization = organizations.find(org => org.externalId === row.original.organizationId);
          if (organization) {
            return (
              <OrganizationTooltip organization={organization}>
                <span>{name}</span>
              </OrganizationTooltip>
            );
          }

          return name;
        },
        Edit: ({ table, column, row }) => {
          return (
            <ExternalOrganizationAutocomplete
              isInvalid={!!validationErrors.organizationId}
              value={selectedOrganization}
              organizations={assignableOrganizations}
              onChange={value => {
                row._valuesCache[column.id] = value;
                table.setCreatingRow(row);
                setSelectedOrganization(value);
                setSelectedUserId(null);
                setValidationErrors({ ...validationErrors, organizationId: undefined });
              }}
              helperText={validationErrors.organizationId ?? ''}
            />
          );
        },
        accessorFn: ({ organizationId }) => {
          const organization = organizations.find(org => org.externalId === organizationId);
          if (organization) {
            return `${organization.name} (${organizationTypeToText(organization.organizationType)})`;
          }

          return undefined;
        },
        header: 'Organisatie',
        id: 'organizationId',
      },
      {
        Edit: ({ table, column, row }) => {
          return (
            <Autocomplete
              value={selectedUserId}
              disabled={!selectedOrganization}
              options={notAssignedRows
                .filter(assignableRole => assignableRole.organizationId === selectedOrganization)
                .map(assignableRole => assignableRole.userId)}
              getOptionLabel={option => users.find(user => user.id === option)?.email ?? 'Onbekende gebruiker'}
              onChange={(e, value) => {
                row._valuesCache[column.id] = value;
                table.setCreatingRow(row);
                setSelectedUserId(value);
                setValidationErrors({ ...validationErrors, userId: undefined });
              }}
              role="option"
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Gebruiker"
                  error={!!validationErrors.userId}
                  helperText={validationErrors.userId}
                />
              )}
            />
          );
        },
        accessorFn: row => users.find(user => user.id === row.userId)?.email,
        header: 'Gebruiker',
        id: 'userId',
      },
      {
        Cell: ({ row, table }) =>
          table.getState().creatingRow !== row && (
            <Typography variant="body2" fontStyle="italic">
              {row.original.isChild
                ? 'Toegekend via bovenliggende organisatie'
                : !editableRows.some(role => role.organizationId === row.original.organizationId)
                  ? 'Geen rechten op organisatie'
                  : ''}
            </Typography>
          ),
        accessorFn: row => row,
        columnDefType: 'display',
        header: 'Opmerking',
        id: 'comment',
      },
    ],
    [selectedOrganization, selectedUserId, validationErrors, notAssignedRows]
  );

  const table = useDvtTable({
    columns,
    createDisplayMode: 'modal',
    data: assignedRows,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnPinning: false,
    enableDensityToggle: false,
    enableEditing: true,
    enableExpanding: true,
    enableFullScreenToggle: false,
    enableGrouping: true,
    enableHiding: false,
    enablePagination: false,
    enableRowActions: true,
    enableRowVirtualization: import.meta.env.MODE !== 'test',
    enableSorting: false,
    enableTopToolbar: true,
    getSubRows: row => row.children,
    muiTableBodyRowProps: ({ row, table }) => ({
      sx: {
        backgroundColor:
          table.getState().creatingRow !== row &&
          (row.original.isChild || !editableRows.some(role => isRoleAssignmentEqual(role, row.original)))
            ? 'rgba(0, 0, 0, 0.04)'
            : undefined,
      },
    }),

    muiTableContainerProps: { sx: { maxHeight: '600px' } },
    onCreatingRowCancel: () => {
      setSelectedOrganization(null);
      setSelectedUserId(null);
      setValidationErrors({});
    },

    onCreatingRowSave: ({ exitCreatingMode, values, table }) => {
      const newValidationErrors = validateUser(values);
      if (Object.values(newValidationErrors).some(error => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      const newRole = editableRows.find(role => isRoleAssignmentEqual(role, values));
      if (!newRole) {
        return;
      }
      setSelectedOrganization(null);
      setSelectedUserId(null);
      onChange([...value, newRole]);
      exitCreatingMode();

      // Scroll to the bottom of the table to show the newly added row
      /* v8 ignore next */
      rowVirtualizerInstanceRef.current?.scrollToIndex(table.getFilteredRowModel().rows.length, { align: 'start' });
    },

    positionActionsColumn: 'last',

    positionCreatingRow: 'bottom',

    renderBottomToolbarCustomActions: ({ table }) => (
      <Button
        disabled={!!table.getState().creatingRow}
        variant="contained"
        color="secondary"
        onClick={() => {
          table.setCreatingRow(true);
        }}
      >
        Dienstrol toevoegen
      </Button>
    ),
    renderRowActions: ({ row }) => (
      <IconButton
        disabled={
          // If the row has a parent, it is not deletable
          row.original.isChild ||
          // If the row is not editable, it is not deletable
          !editableRows.some(role => isRoleAssignmentEqual(role, row.original))
        }
        onClick={() => {
          onChange(value.filter(v => !isRoleAssignmentEqual(v, row.original)));
        }}
        aria-label="Verwijderen"
      >
        <Delete />
      </IconButton>
    ),
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
    ...tableOptions,
  });

  return <MaterialReactTable table={table} />;
}

function validateUser(
  serviceRole: ModelsServiceRoleAssignmentToUserForServiceRole
): Record<string, string | undefined> {
  return {
    organizationId: serviceRole.organizationId ? undefined : 'Selecteer een organisatie',
    userId: serviceRole.userId ? undefined : 'Selecteer een gebruiker',
  };
}

function isRoleAssignmentEqual(
  a: ModelsServiceRoleAssignmentToUserForServiceRole,
  b: ModelsServiceRoleAssignmentToUserForServiceRole
) {
  return a.organizationId === b.organizationId && a.userId === b.userId;
}
