import { ModelsOrganizationType } from '#edsn/api/idm-bff';

export function organizationTypeToText(organizationType: ModelsOrganizationType): string {
  switch (organizationType) {
    case ModelsOrganizationType.organization:
      return 'Organisatie';
    case ModelsOrganizationType.marketparticipant:
      return 'Marktpartij';
    case ModelsOrganizationType.subunit:
      return 'Organisatieonderdeel';
  }
}
