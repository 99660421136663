import NavigateNext from '@mui/icons-material/NavigateNext';
import { Link, Breadcrumbs as MuiBreadcrumbs, type SxProps, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export interface BreadcrumbsProps {
  items: { label: string; to?: string | number }[];
  sx?: SxProps;
}

export function Breadcrumbs({ items, sx = {} }: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
      sx={{ li: { mb: 'inherit' }, ...sx }}
    >
      {items.map(({ label, to }, i) =>
        i < items.length - 1 && to ? (
          <Link
            key={label}
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={to as string}
            className={i === 2 ? 'sentry-mask' : undefined}
          >
            {label}
          </Link>
        ) : (
          <Typography key={label} color="text.primary" className={i === 2 ? 'sentry-mask' : undefined}>
            {label}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
