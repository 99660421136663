import { Tooltip, type TooltipProps } from '@mui/material';
import type { ModelsOrganizationResponse } from '#edsn/api/idm-bff';
import { organizationTypeToText } from '../../../utils/organizationType';

export interface OrganizationChipProps extends Omit<TooltipProps, 'title'> {
  organization: ModelsOrganizationResponse;
}

export function OrganizationTooltip({ organization, ...rest }: OrganizationChipProps) {
  return (
    <Tooltip
      enterDelay={500}
      leaveDelay={200}
      arrow
      placement="right"
      title={
        <div className="flex flex-col">
          <h2>{organization.name}</h2>
          <div>Type: {organizationTypeToText(organization.organizationType)}</div>
          {organization.marketRole && <div>Marktrol: {organization.marketRole}</div>}
          {organization.ean13 && <div>EAN13: {organization.ean13}</div>}
          {organization.registrationNumber && <div>KvK: {organization.registrationNumber}</div>}
        </div>
      }
      {...rest}
    />
  );
}
