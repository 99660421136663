/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Identity Management
 * The Identity Management API can be used to manage application roles,
users, market participants and service accounts.

## Release notes:
### Version 0.0.1
- Initial release
 * OpenAPI spec version: 0.0.1
 */

export type ModelsOrganizationType = (typeof ModelsOrganizationType)[keyof typeof ModelsOrganizationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModelsOrganizationType = {
  marketparticipant: 'marketparticipant',
  subunit: 'subunit',
  organization: 'organization',
} as const;
