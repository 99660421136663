import { createServiceRoleAssignmentsForUserOrServiceAccount } from './serviceRoles';
import { ModelsPaginatedUserResponseV2, ModelsUserResponseV2, ModelsUserResponseV2Role } from './generated/model';
import { createMarketParticipant } from './organizations';

export const createUser = (overrides: Partial<ModelsUserResponseV2> = {}): ModelsUserResponseV2 => ({
  id: '7a9d9a1e-df11-4b1e-8817-135d76501e0d',
  userName: 'dwaterschoot',
  givenName: 'Diemer',
  familyName: 'Waterschoot',
  email: 'diemer.waterschoot@edsn.nl',
  role: ModelsUserResponseV2Role.operator,
  active: true,
  lastLoginTime: '2024-02-23T15:31:37Z',
  serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),
  applicationRoles: [
    {
      marketRoles: [],
      id: '44980820-b054-4966-86f6-a6ff6d5afe43',
      name: 'filegateway:ui:write',
      description: 'Write-access to the File Gateway UI.',
    },
    {
      marketRoles: [],
      id: 'ca20d2aa-1790-4acf-8c46-fca9a3253e3a',
      name: 'filegateway:transfer:write',
      description: 'Ability to create transfers in the File Gateway.',
    },
  ],
  organizations: [
    {
      externalId: '',
      id: 'adbb132c-cf80-4941-ad78-7546ef95acc6',
      name: 'Powerhouse Inclusive B.V.',
      organizationType: 'subunit',
      adminUsers: [
        {
          id: 'johndoe',
          email: 'john.doe@edsn.nl',
        },
      ],
      organizationStructure: {
        externalId: '',
        id: '',
        name: '',
        registrationNumber: '12918372',
        nameFacade: '',
        registrationNumberOrigin: '',
        marketParticipants: [],
      },
      ean13: '1938287462810',
      status: 'actief',
      marketRole: 'LV',
      parentId: 'dc9397d9-000d-480d-8709-05179db21d94',
    },
  ],
  ...overrides,
});

export const createUsers = (): ModelsPaginatedUserResponseV2 => ({
  nextCursor: '',
  pageSize: 10,
  result: [
    {
      id: '48f04657-ad47-41e1-966e-65fe9f96a888',
      userName: 'jisk.reijn',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'Jisk',
      familyName: 'Reijn',
      email: 'jisk.reijn@edsn.nl',
      role: ModelsUserResponseV2Role.operator,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),
      applicationRoles: [
        {
          marketRoles: ['EDSN'],
          id: '44980820-b054-4966-86f6-a6ff6d5afe44',
          name: 'filegateway:ui:write',
          description: 'Write-access to the File Gateway UI.',
        },
        {
          marketRoles: [],
          id: 'ca20d2aa-1790-4acf-8c46-fca9a3253e3a',
          name: 'filegateway:transfer:write',
          description: 'Ability to create transfers in the File Gateway.',
        },
        {
          marketRoles: [],
          id: '9fb8d8fe-295e-4724-8829-eff7ce8371f5',
          name: 'test_role_abc',
          description: 'Test',
        },
        {
          marketRoles: [],
          id: 'dedd8c45-887b-402f-aa46-501262520ccd',
          name: 'mpr:admin:config',
        },
        {
          marketRoles: [],
          id: '1140056d-3699-4126-8dec-64070f17b662',
          name: 'filegateway:ui:read',
          description: 'Read-access to the File Gateway UI.',
        },
        {
          marketRoles: [],
          id: '6cb35769-e3c3-4d17-9bc2-b2eae27bc6a4',
          name: 'cmp:application:read',
          description: 'Allows access to C-MP.',
        },
      ],

      organizations: [
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
          name: 'Enrgy Included B.V.',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          ean13: '88163718371893',
          status: 'actief',
          marketRole: 'EDSN',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b13',
          name: 'Enrgy Included B.V.',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          ean13: '88163718371893',
          status: 'actief',
          marketRole: 'EDSN',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b13',
          name: 'Enrgy Included B.V.',
          organizationType: 'organization',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          registrationNumber: '00000000',
          status: 'actief',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
      ],
    },
    {
      id: '8534865f-f30d-4119-a374-203ad0f8dcb6',
      userName: 'daimy.van.veggel',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'Daimy',
      familyName: 'van Veggel',
      email: 'daimy.van.veggel@edsn.nl',
      role: ModelsUserResponseV2Role.user,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),

      applicationRoles: [
        {
          marketRoles: [],
          id: '0e2d599f-b87e-4f0d-9fcb-712777455d87',
          name: 'mpr:admin:write',
        },
        {
          marketRoles: [],
          id: '8b22c972-f21d-46e2-841e-ed6ddaf04965',
          name: 'mpr:admin:read',
        },
        {
          marketRoles: [],
          id: 'd6fd297b-db32-4015-b2b6-2bcd6e14ac88',
          name: 'getp4:write',
        },
        {
          marketRoles: [],
          id: 'dedd8c45-887b-402f-aa46-501262520ccd',
          name: 'mpr:admin:config',
        },
      ],

      organizations: [
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
          name: 'Enrgy Included B.V.',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          ean13: '88163718371893',
          status: 'actief',
          marketRole: 'EDSN',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
      ],
    },
    {
      id: 'eaf858b6-9a2d-41bd-ab76-bce881bb8737',
      userName: 'ronnie.van.der.graaf@edsn.nl',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'Ronnie',
      familyName: 'van der Graaf',
      email: 'ronnie.van.der.graaf@edsn.nl',
      role: ModelsUserResponseV2Role.user,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),

      applicationRoles: [],

      organizations: [
        {
          externalId: '',
          id: '19a05c48-95c5-492d-8195-a0658baa71d2',
          name: 'Interactive Developers Nederland B.V.',
          ean13: '8946184726372',
          status: 'actief',
          marketRole: 'ODA',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '09183777',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
        },
      ],
    },
    {
      id: 'e669e527-cbaa-4bc3-b5b7-1c3b9db915da',
      userName: 'stefano.grimberg',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'Stefano',
      familyName: 'Grimberg',
      email: 'stefano.grimberg@edsn.nl',
      role: ModelsUserResponseV2Role.user,
      active: false,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),

      applicationRoles: [
        {
          marketRoles: [],
          id: '6cb35769-e3c3-4d17-9bc2-b2eae27bc6a4',
          name: 'cmp:application:read',
          description: 'Allows access to C-MP.',
        },
        {
          marketRoles: [],
          id: 'dedd8c45-887b-402f-aa46-501262520ccd',
          name: 'mpr:admin:config',
        },
      ],

      organizations: [
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
          name: 'Enrgy Included B.V.',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          ean13: '88163718371893',
          status: 'actief',
          marketRole: 'EDSN',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
      ],
    },
    {
      id: '00f44338-d7c9-4938-b42d-0831ed4fda5f',
      userName: 'anthonie.stoffer@edsn.nl',
      lastLoginTime: undefined,
      givenName: 'Antonie',
      familyName: 'Stoffer',
      email: 'anthonie.stoffer@edsn.nl',
      role: ModelsUserResponseV2Role.user,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),

      applicationRoles: [
        {
          marketRoles: [],
          id: '0e2d599f-b87e-4f0d-9fcb-712777455d87',
          name: 'mpr:admin:write',
        },
        {
          marketRoles: [],
          id: '8b22c972-f21d-46e2-841e-ed6ddaf04965',
          name: 'mpr:admin:read',
        },
      ],

      organizations: [
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
          name: 'Enrgy Included B.V.',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          ean13: '88163718371893',
          status: 'actief',
          marketRole: 'EDSN',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
      ],
    },
    {
      id: 'a173e983-c7e6-4cfe-befb-4bc4679beeb0',
      userName: 'mirjan.broer@edsn.nl',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'Mirjan',
      familyName: 'Broer',
      email: 'mirjan.broer@edsn.nl',
      role: ModelsUserResponseV2Role.operator,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),

      applicationRoles: [],

      organizations: [
        {
          externalId: '',
          id: 'eeca3b4a-f548-4ed2-970f-286166cc9b12',
          name: 'Enrgy Included B.V.',
          organizationType: 'subunit',
          organizationStructure: {
            externalId: '',
            id: '',
            name: '',
            registrationNumber: '94712623',
            nameFacade: '',
            registrationNumberOrigin: '',
            marketParticipants: [],
          },
          ean13: '88163718371893',
          status: 'actief',
          marketRole: 'EDSN',
          parentId: '0bbc2424-4faa-4c5c-92d3-0c78192953c0',
        },
      ],
      unstable_carIds: ['123', '456'],
    },
    {
      id: '4af5fbb4-dae3-437d-9d96-870a81628903',
      userName: 'dick.schoof@edsn.nl',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'Dick',
      familyName: 'Schoof',
      email: 'dick.schoof@edsn.nl',
      role: ModelsUserResponseV2Role.operator,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),

      applicationRoles: [],
      organizations: [createMarketParticipant()],
      unstable_carIds: ['123', '456'],
    },
    {
      id: '83281c12-5c18-47a5-b54d-b4f2ccdb348a',
      userName: 'market-participant-user@edsn.nl',
      lastLoginTime: '2024-02-23T15:31:37Z',
      givenName: 'market-participant',
      familyName: 'user',
      email: 'market-participant-user@edsn.nl',
      role: ModelsUserResponseV2Role.operator,
      active: true,
      serviceRoles: createServiceRoleAssignmentsForUserOrServiceAccount(),
      applicationRoles: [],
      organizations: [createMarketParticipant()],
      unstable_carIds: ['123', '456'],
    },
    // User that represents mocked OAuth token
    createUser({
      email: 'imi_tatie@edsn.nl',
      givenName: 'Imi',
      familyName: 'Tatie',
      id: 'johndoe',
      role: ModelsUserResponseV2Role.operator,
    }),
    createUser(),
  ],
});

export const createSuperAdmin = (): ModelsUserResponseV2 =>
  createUser({
    email: 'admin@edsn.nl',
    givenName: 'Admin',
    familyName: 'Super',
    id: 'admin',
    role: ModelsUserResponseV2Role.admin,
  });

export const createHelpdesk = (): ModelsUserResponseV2 =>
  createUser({
    email: 'helpdesk@edsn.nl',
    givenName: 'Help',
    familyName: 'Desk',
    id: 'helpdesk',
    role: ModelsUserResponseV2Role.helpdesk,
  });
