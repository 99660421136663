import { Autocomplete, TextField } from '@mui/material';
import { forwardRef } from 'react';
import type { ModelsOrganizationResponse } from '#edsn/api/idm-bff';
import { organizationTypeToText } from '../../../utils/organizationType';
import type { AutocompleteProps } from '@mui/material';

// TODO: this is instant legacy code. We need to change the IDM facade model to
// support only 1 organization id. The externalId needs to replace the id, and
// the id needs to be removed. After that change, both the
// OrganizationAutocomplete and ExternalOrganizationAutocomplete can be merged
// into 1 component.

type ExternalOrganizationAutocompleteProps = {
  organizations: ModelsOrganizationResponse[];
  isInvalid: boolean;
  onChange: (value: string | null) => void;
  helperText?: string;
} & Omit<AutocompleteProps<string, false, false, false>, 'options' | 'renderInput' | 'onChange'>;

export const ExternalOrganizationAutocomplete = forwardRef<HTMLInputElement, ExternalOrganizationAutocompleteProps>(
  function ExternalOrganizationAutocomplete({ organizations, isInvalid, helperText, onChange, ...rest }, ref) {
    return (
      <Autocomplete
        ref={ref}
        role="option"
        filterSelectedOptions
        getOptionLabel={option => {
          const organization = organizations.find(({ externalId }) => externalId === option);

          if (!organization) {
            return 'Onbekende organisatie';
          }

          return `${organization.name} (${[organizationTypeToText(organization.organizationType), organization.ean13]
            .filter(Boolean)
            .join(', ')})`;
        }}
        options={organizations.map(({ externalId }) => externalId)}
        renderInput={params => (
          <TextField
            {...params}
            label="Organisatie"
            error={isInvalid}
            helperText={helperText ?? 'Selecteer de organisatie waarvoor je toegang wilt verlenen'}
          />
        )}
        {...rest}
        onChange={(event, selectedOption) => {
          onChange(selectedOption);
        }}
      />
    );
  }
);
