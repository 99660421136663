import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import type {
  ModelsAssignableServiceRole,
  ModelsOrganizationResponse,
  ModelsServiceAccountResponseV2,
  ModelsServiceRoleAssignmentsForServiceRole,
  ModelsUserResponseV2,
} from '#edsn/api/idm-bff';
import { ServiceRoleServiceAccountTable } from './ServiceRoleServiceAccountTable';
import { ServiceRoleUserTable } from './ServiceRoleUserTable';
import type { ReactNode } from 'react';

export type ServiceRoleFormData = ModelsServiceRoleAssignmentsForServiceRole;

interface ServiceRoleFormProps {
  serviceRoleAssignments: ModelsServiceRoleAssignmentsForServiceRole;
  organizations: ModelsOrganizationResponse[];
  editableServiceRoles: ModelsAssignableServiceRole[];
  users: ModelsUserResponseV2[];
  onSubmit: (data: ServiceRoleFormData) => void;
  isSubmitting: boolean;
  actionSlot?: ReactNode;
  name: string;
  serviceAccounts: ModelsServiceAccountResponseV2[];
}

export function ServiceRoleForm({
  organizations,
  serviceRoleAssignments,
  onSubmit,
  actionSlot,
  editableServiceRoles,
  isSubmitting,
  users,
  name,
  serviceAccounts,
}: ServiceRoleFormProps) {
  const { control, handleSubmit } = useForm<ServiceRoleFormData>({
    defaultValues: {
      serviceAccounts: serviceRoleAssignments.serviceAccounts,
      users: serviceRoleAssignments.users,
    },
  });

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data))} className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Typography variant="h3">Gebruikers</Typography>
          <Controller
            control={control}
            name="users"
            render={({ field }) => (
              <ServiceRoleUserTable
                serviceRoleName={name}
                users={users}
                editableServiceRoles={editableServiceRoles}
                organizations={organizations}
                {...field}
              />
            )}
          />
        </div>

        <div className="flex flex-col gap-4">
          <Typography variant="h3">Service Accounts</Typography>
          <Controller
            control={control}
            name="serviceAccounts"
            render={({ field }) => (
              <ServiceRoleServiceAccountTable
                serviceRoleName={name}
                serviceAccounts={serviceAccounts}
                editableServiceRoles={editableServiceRoles}
                organizations={organizations}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <div className="flex gap-1">
        <LoadingButton loading={isSubmitting} type="submit">
          Opslaan
        </LoadingButton>
        {actionSlot}
      </div>
    </form>
  );
}
