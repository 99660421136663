import type { ModelsOrganizationResponse } from '#edsn/api/idm-bff';

export function isOrganizationPartOfOrganizations(organizations: ModelsOrganizationResponse[], organizationId: string) {
  for (const org of organizations) {
    if (isOrganizationPartOfOrganization(org, organizationId)) {
      return true;
    }
  }
  return false;
}

export function isOrganizationPartOfOrganization(organization: ModelsOrganizationResponse, organizationId: string) {
  if (organization.organizationStructure.externalId === organizationId) {
    return true;
  }

  for (const marketParticipant of organization.organizationStructure.marketParticipants) {
    if (marketParticipant.externalId === organizationId) {
      return true;
    }

    for (const subUnit of marketParticipant.subUnits) {
      if (subUnit.externalId === organizationId) {
        return true;
      }
    }
  }

  return false;
}
