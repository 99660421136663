import { Box, Typography } from '@mui/material';

export interface DetailHeadingProps {
  name?: string;
}

export function DetailHeading({ name }: DetailHeadingProps) {
  return (
    <Typography mb={4} mt={2} variant="h1">
      Details voor{' '}
      {name ? (
        <Box component="span" color="secondary.main" className="sentry-mask">
          {name}
        </Box>
      ) : (
        '…'
      )}
    </Typography>
  );
}

export default DetailHeading;
