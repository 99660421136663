import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { getUsersV2 } from './generated/idm-bff-api';
import { ModelsPaginatedUserResponseV2, ResponsesProblemDetails, GetUsersV2Params } from './generated/model';
import { useEffect, useMemo } from 'react';

export function useGetUsersV2Paged(
  params?: GetUsersV2Params,
  options?: {
    query?: Omit<
      UseInfiniteQueryOptions<
        ModelsPaginatedUserResponseV2,
        ResponsesProblemDetails | void,
        ModelsPaginatedUserResponseV2,
        ModelsPaginatedUserResponseV2
      >,
      'queryKey' | 'queryFn' | 'getPreviousPageParam' | 'getNextPageParam'
    >;
  }
) {
  const q = useInfiniteQuery<
    ModelsPaginatedUserResponseV2,
    ResponsesProblemDetails | void,
    ModelsPaginatedUserResponseV2
  >({
    queryKey: ['users', 'infinite', params],
    queryFn: async ({ pageParam = '' }) => {
      const response = await getUsersV2({
        ...params,
        cursor: pageParam,
        pageSize: 1000,
      });
      return response;
    },
    // it is important that this value is undefined for react query to know that
    // there are no more pages to fetch
    getNextPageParam: lastPage => lastPage.nextCursor || undefined,
    ...options?.query,
  });

  useEffect(() => {
    if (q.hasNextPage && !q.isFetchingNextPage) {
      q.fetchNextPage();
    }
  }, [q.hasNextPage, q.isFetchingNextPage, q.fetchNextPage]);

  const users = useMemo(() => q.data?.pages.flatMap(page => page.result) ?? [], [q.data]);

  return {
    ...q,
    done: !q.hasNextPage && !q.isFetchingNextPage,
    users,
  };
}
