import {
  ModelsAssignableServiceRole,
  ModelsServiceRole,
  ModelsServiceRoleAssignmentForUserOrServiceAccount,
  ModelsServiceRoleAssignmentsForServiceRole,
  ModelsServiceRoleAssignmentToServiceAccountForServiceRole,
  ModelsServiceRoleAssignmentToUserForServiceRole,
} from './generated/model';

export function createServiceRoleAssignmentForUserOrServiceAccount(): ModelsServiceRoleAssignmentForUserOrServiceAccount {
  return {
    organizationId: 'market-participant-id',
    serviceRoleName: 'Already assigned',
    children: [],
  };
}

export function createServiceRoleAssignmentsForUserOrServiceAccount(): ModelsServiceRoleAssignmentForUserOrServiceAccount[] {
  return [
    createServiceRoleAssignmentForUserOrServiceAccount(),
    {
      organizationId: 'main-org-id',
      serviceRoleName: 'Assigned, with children',
      children: [
        {
          organizationId: 'market-participant-id',
          children: [
            {
              organizationId: 'subunit-id',
            },
          ],
        },
      ],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Role for Another Organization',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Another Role for Another Organization',
      children: [],
    },
    {
      organizationId: 'market-participant-id',
      serviceRoleName: 'Same Role For Multiple Oraganizations',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Same Role For Multiple Oraganizations',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Not Editable',
      children: [],
    },
  ];
}

export function createServiceRoleAssignmentToServiceAccountForServiceRoleResponse(): ModelsServiceRoleAssignmentToServiceAccountForServiceRole {
  return {
    organizationId: 'market-participant-id',
    serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
    children: [],
  };
}

export function createServiceRoleAssignmentToServiceAccountForServiceRoles(): ModelsServiceRoleAssignmentToServiceAccountForServiceRole[] {
  return [
    createServiceRoleAssignmentToServiceAccountForServiceRoleResponse(),
    {
      organizationId: 'market-participant-id',
      serviceAccountId: 'market-participant-service-account-id',
      children: [],
    },
    {
      organizationId: 'market-participant-id',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
    {
      organizationId: 'main-org-id',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
    {
      organizationId: 'market-participant-id',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceAccountId: 'f7ad0fb0-af7f-4fb8-b3ff-1693c0dab48a',
      children: [],
    },
  ];
}

export function createServiceRoleAssignmentToUserForServiceRoleResponse(): ModelsServiceRoleAssignmentToUserForServiceRole {
  return {
    organizationId: 'market-participant-id',
    userId: '83281c12-5c18-47a5-b54d-b4f2ccdb348a',
    children: [],
  };
}

export function createServiceRoleAssignmentToUserForServiceRoles(): ModelsServiceRoleAssignmentToUserForServiceRole[] {
  return [
    createServiceRoleAssignmentToUserForServiceRoleResponse(),
    {
      organizationId: 'main-org-id',
      userId: '83281c12-5c18-47a5-b54d-b4f2ccdb348a',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      userId: '83281c12-5c18-47a5-b54d-b4f2ccdb348a',
      children: [],
    },
  ];
}

export function createServiceRoleAssignmentsForServiceRoleResponse(): ModelsServiceRoleAssignmentsForServiceRole {
  return {
    serviceAccounts: createServiceRoleAssignmentToServiceAccountForServiceRoles(),
    users: createServiceRoleAssignmentToUserForServiceRoles(),
  };
}

export function createEditableServiceRoles(): ModelsAssignableServiceRole[] {
  return [
    {
      organizationId: 'market-participant-id',
      serviceRoleName: 'Not yet assigned',
      children: [],
    },
    {
      organizationId: 'market-participant-id',
      serviceRoleName: 'Already assigned',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Role for Another Organization',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Another Role for Another Organization',
      children: [],
    },
    {
      organizationId: 'market-participant-id',
      serviceRoleName: 'Same Role For Multiple Oraganizations',
      children: [],
    },
    {
      organizationId: '726eca22-1452-4f4c-818e-30f8e45af0d5',
      serviceRoleName: 'Same Role For Multiple Oraganizations',
      children: [],
    },
    {
      organizationId: 'main-org-id',
      serviceRoleName: 'Assigned, with children',
      children: [
        {
          organizationId: 'market-participant-id',
          children: [],
        },
      ],
    },
  ];
}

export function createServiceRole(): ModelsServiceRole {
  return {
    art: 'ART',
    businessSolution: 'Business Solution',
    descriptionNl: 'Description NL',
    displayNameNl: 'Display Name NL',
    name: 'Already assigned',
  };
}

export function createServiceRoles(): ModelsServiceRole[] {
  return [
    createServiceRole(),
    {
      art: 'ART',
      businessSolution: 'Business Solution',
      descriptionNl: 'Description NL',
      displayNameNl: 'Display Name NL',
      name: 'Assigned with children',
    },
    {
      art: 'ART',
      businessSolution: 'Business Solution',
      descriptionNl: 'Description NL',
      displayNameNl: 'Display Name NL',
      name: 'Not yet assigned',
    },
  ];
}

