import { Alert, AlertTitle, Box, Button, Container } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import type { ResponsesProblemDetails } from '#edsn/api/idm-bff';
import {
  getGetServiceRolesAssignmentsForServiceRoleQueryKey,
  useGetEditableServiceRoles,
  useGetOrganizations,
  useGetServiceAccountsV2,
  useGetServiceRolesAssignmentsForServiceRole,
  useGetUsersV2Paged,
  usePutServiceRoleAssignmentsForServiceRole,
} from '#edsn/api/idm-bff';
import { errorMessages, getErrorDetail } from '#edsn/shared';
import { Breadcrumbs } from '#edsn/ui';
import DetailHeading from '../../../detail-heading/DetailHeading';
import { ServiceRoleForm } from '../../../entities/service-role/ServiceRoleForm';
import type { ServiceRoleFormData } from '../../../entities/service-role/ServiceRoleForm';

export function ServiceRole() {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  if (!id) {
    throw new Error('No id provided');
  }
  const navigate = useNavigate();

  const { data: serviceRoleAssignments, isSuccess: isSuccessServiceRolesAssignmentsForServiceRole } =
    useGetServiceRolesAssignmentsForServiceRole(id ?? '');

  const { data: serviceAccounts } = useGetServiceAccountsV2({});
  const { users, done } = useGetUsersV2Paged({});
  const { data: editableServiceRoles } = useGetEditableServiceRoles();
  const { data: organizations } = useGetOrganizations({});

  const [saveError, setSaveError] = useState<ResponsesProblemDetails>();

  const { mutateAsync, isLoading } = usePutServiceRoleAssignmentsForServiceRole({
    mutation: {
      onSuccess() {
        queryClient.refetchQueries({
          queryKey: getGetServiceRolesAssignmentsForServiceRoleQueryKey(id),
        });
      },
    },
  });

  const onSubmit = useCallback(
    async (submittedData: ServiceRoleFormData) => {
      try {
        await mutateAsync({
          data: submittedData,
          name: id,
        });

        navigate('../');
      } catch (res) {
        const response = res as Response;
        const error: ResponsesProblemDetails =
          response.headers?.get('Content-Type') === 'application/json'
            ? await response.json()
            : { detail: 'Neem contact op als dit vaker gebeurt', title: `${response.status} ${response.statusText}` };
        setSaveError(error);
      }
    },
    [navigate]
  );

  return (
    <Container sx={{ py: 2 }}>
      <Breadcrumbs items={[{ label: 'Home', to: '/' }, { label: 'Applicatierollen', to: '/rollen' }, { label: id }]} />
      <DetailHeading name={id} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {saveError && (
          <Alert severity="error">
            <AlertTitle>
              {errorMessages[saveError.title!] ? errorMessages[saveError.title!] : 'Onbekende fout.'}
            </AlertTitle>
            {getErrorDetail(saveError.detail)}
          </Alert>
        )}

        {isSuccessServiceRolesAssignmentsForServiceRole &&
          editableServiceRoles &&
          organizations &&
          serviceAccounts &&
          done && (
            <ServiceRoleForm
              serviceRoleAssignments={serviceRoleAssignments}
              editableServiceRoles={editableServiceRoles}
              isSubmitting={isLoading}
              name={id}
              onSubmit={onSubmit}
              organizations={organizations}
              serviceAccounts={serviceAccounts}
              users={users}
              actionSlot={
                <Button component={Link} color="inherit" variant="text" to="../">
                  Annuleren
                </Button>
              }
            />
          )}
      </Box>
    </Container>
  );
}

export default ServiceRole;
