import '#edsn/ui/index.css';
import { OidcProvider } from '@axa-fr/react-oidc';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import nlNL from 'date-fns/locale/nl';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { initSentry } from '#edsn/shared/sentry';
import { theme } from '#edsn/ui';
import 'unfonts.css';
import { AuthError } from './auth-error/AuthError';
import { AuthSpinner } from './auth-spinner/AuthSpinner';
import { CiamRoutes } from './CiamRoutes';
import { oidcConfiguration } from './oidcConfiguration';
import './global.css';

if (import.meta.env.VITE_MOCK_API === 'true') {
  const { worker } = await import('./mocks/browser');
  await worker.start({ onUnhandledRequest: 'bypass' });
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const sentryDSN = import.meta.env.VITE_SENTRY_DSN;

// Init sentry and set default maskAll to false
sentryDSN &&
  initSentry(
    {
      dsn: sentryDSN,
      tracePropagationTargets: ['localhost', /^https:\/\/gateway(-(?:dev|acc))?\.edsn\.nl/],
    },
    false
  );

// In dev mode queries run twice @see https://github.com/TanStack/query/issues/3633
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider adapterLocale={nlNL} dateAdapter={AdapterDateFns}>
        <CssBaseline enableColorScheme />
        <OidcProvider
          configuration={oidcConfiguration}
          loadingComponent={AuthSpinner}
          authenticatingComponent={AuthSpinner}
          callbackSuccessComponent={AuthSpinner}
          authenticatingErrorComponent={AuthError}
          sessionLostComponent={() => {
            window.location.reload();
            return null;
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CiamRoutes />
          </QueryClientProvider>
        </OidcProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </StrictMode>
);
