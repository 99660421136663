export interface ErrorMessage {
  title: string;
  message: string;
  detail: {
    failedPolicyRequirements: [
      {
        property: string;
        message: string;
      }
    ];
  };
}

// this is the dictionary of error messages that are returned by the backend, the key is the raw error message and the value is the message that is shown to the user
// TODO: these messages should be translated for use in other apps
export const errorMessages: Record<string, string> = {
  'Internal Server Error': 'Er is een fout opgetreden.',
  'No matching privileges found': 'U heeft niet genoeg rechten om deze actie uit te voeren.',
  adminOfOrg: 'U bent al beheerder van deze organisatie.',
  userName: 'Dit e-mailadres is al in gebruik.',
};

const genericErrorMessage = 'Neem contact op met support als deze fout blijft optreden.';

// This function tries to parse the error detail for a better specific error message. It falls back to a generic error message if it can't find a better one.
// The detail messages are also defined in the errorMessages dictionary.
export const getErrorDetail = (error: string | undefined) => {
  if (!error) {
    return genericErrorMessage;
  }

  try {
    const parsedError: ErrorMessage = JSON.parse(error);

    let message = genericErrorMessage;
    switch (parsedError?.message) {
      case 'Policy validation failed': {
        // first try to get the failed policy from the detail
        const failedPolicy = parsedError.detail?.failedPolicyRequirements
          ? parsedError.detail?.failedPolicyRequirements[0]
          : undefined;
        if (failedPolicy) {
          message = errorMessages[failedPolicy.property] ?? 'Onbekende validatiefout';
        } else {
          message = 'Onbekende validatiefout';
        }
        break;
      }
      default:
        message = errorMessages[parsedError.message] ?? genericErrorMessage;
        break;
    }
    // eslint-disable-next-line no-console
    console.error('Error detail: ', error); // make sure to log details to console
    return message;
  } catch (err) {
    return genericErrorMessage;
  }
};
